@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mohave:wght@300;400;500;600&family=Oswald:wght@200&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
}

.slide {
  opacity: 0;
  transition: opacity 0.5s ease;
  /* transition-duration: .25s ease; */
}
.slide.active {
  opacity: 1;
  transition: opacity 0.5s ease;
  /* transition-duration: .25s; */
}